import { createGlobalStyle } from "styled-components";

import { properties, colors, functions, snippets, resolutions } from ".";
import ananse_pose from "../assets/raster-images/ananse/action-pose.png";
import pattern_01 from "../assets/vector-images/patterns/01.svg";



const GlobalStyles = createGlobalStyle`
  ${''/* ################# */}  
  ${''/* # ROOT ELEMENTS # */}
  ${''/* ################# */}
  *, *::before, *::after {
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    font-size: 14px;
    font-optical-sizing: auto;
    font-style: normal;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    color: ${colors.grey3};
  }
  html {
    height: 100%;
  }
  html, body {
    min-height: 100vh;
  }
  body {
    max-width: 100%;
    position: relative;
    overflow-x: hidden;
  }
  body, h1, h2, h3, h4, h5, h6, p, figure {
    margin: 0;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    font-family: "Radwave";
    color: ${colors.grey13};
  }

  div, li, p, ul, ol, span {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }
  ol, ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  figure {
    text-align: center;
    figcaption {
      text-align: left;
    }
  }
  img {
    object-position: center;
    object-fit: contain;
    vertical-align: middle;
    user-select: none;
  }
  a {
    outline: none;
    text-decoration: none;
    &:hover{
      text-decoration: none;
    }
  }
  ${''/* FORM ELEMENTS */}
  input, button, textarea, select {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
  }
  input, textarea {
    font-size: 14px;
    &:focus, &:hover {
      outline: none;
    }
    &::placeholder {
      color: ${colors.grey2};
    }
  }
  button {
    cursor: pointer;
    user-select: none;
  }
  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }


  ${''/* ########### */}
  ${''/* # CLASSES # */}
  ${''/* ########### */}
  ${'' /* Top-Level Page Component */}
  .root-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: ${colors.grey5};
    position: relative;
  }

  ${'' /* Primary Header */}
  .root-header {
    background-color: ${colors.white};
    position: sticky;
    top: 0;
    z-index: ${properties.highestZIndex};
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.1);
    .header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2rem;
      height: ${properties.navHeight};
      max-width: ${properties.maxPageWidth};
      width: 100%;
      margin: 0 auto;
      @media(max-width: ${resolutions.tabletLarge}) {
        height: auto;
        padding: 0.5rem 1rem;
      }
      #logo {
        width: 85px;
      }
      .sidebar-toggle {
        display: none;
        @media(max-width: ${resolutions.tabletLarge}) {
          display: block;
          height: 1.5rem;
          width: 1.5rem;
        }
      }
      .user-avatar {
        .details {
          @media(max-width: ${resolutions.tabletLarge}) {
            display: none;
          }
        }
      }
    }
  }

  ${'' /* Page Content (Differs per path) */}
  .root-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    &>* {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .page-wrapper {
      display: flex;
      flex-direction: column;
      padding: 3rem 2rem;
      flex: 1;
      max-width: ${properties.maxPageWidth};
      width: 100%;
      margin: 0 auto;
      @media(max-width: ${resolutions.tabletLarge}) {
        padding: 2rem 1rem;
      }
      &>* {
        width: 100%;
      }
      &.field-width {
        max-width: ${properties.maxFieldWidth};
      }
      &.centered {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  header, .heading {
    margin-bottom: 2rem;
    .title {
      font-size: 2rem;
      font-weight: 700;
      color: ${colors.grey13};
    }
    .description {
      margin-top: 0.5rem;
    }
  }

  .heading {
    .title {
      font-size: 1.2rem;
    }
  }

  .bg-action-pose {
    position: relative;
    z-index: 1;
    &::after {
      content: "";
      min-width: 200px;
      width: 40vw;
      max-width: 650px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background-image: url(${ananse_pose});
      background-size: cover;
      background-position: left;
      z-index: -1;
      @media(max-width: ${resolutions.tabletLarge}) {
        display: none;
      }
    }
    &.faded {
      &::after {
        opacity: 0.1;
      }
    }
  }
  .bg-pattern-01 {
    background-image: url(${pattern_01});
    background-color: ${colors.primary};
    background-size: cover;
    background-position: bottom;
    background-position: fixed;
    .page-wrapper {
      h1, h2, h3, h4, h5, h6,
      figcaption, div, p, ul, li {
        color: ${colors.white} !important;
      }
    }
  }

  .syncing {
    img {
      animation: sync 1s linear infinite;
    } 
    @keyframes sync {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }

  .grid {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    .full-row {
      grid-column: 1 / -1;
    }
  }

  .bold {
    font-weight: 700;
  }
  .semi-bold {
    font-weight: 600;
  }


  ${'' /* DISPLAY FLEX */}
  .flex {
    display: flex;
    gap: 7px;
    &.column {
      flex-direction: column;
    }
    &.wrap {
      flex-wrap: wrap;
    }
    &.inline {
      display: inline-flex;
    }
    
    ${'' /* (Row) Main & Cross Axes */}
    &.flex-start {
      justify-content: flex-start;
      align-items: flex-start;  
    }
    &.center {
      justify-content: center;
      align-items: center;
    }
    &.flex-end {
      justify-content: flex-end;
      align-items: flex-end;
    }
    ${'' /* (Row) Main Axis */}
    &.justify-start {
      justify-content: flex-start;
    }
    &.justify-center {
      justify-content: center;
    }
    &.justify-end {
      justify-content: flex-end;
    }
    &.justify-between {
      justify-content: space-between;
    }
    ${'' /* (Row) Cross Axis */}
    &.align-start {
      align-items: flex-start;
    }
    &.align-center {
      align-items: center;
    }
    &.align-end {
      align-items: flex-end;
    }
    &.align-between {
      align-items: space-between;
    }
  }
  .flex-fill {
    flex: 1;
  }

  .grid-list {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    .grid-item {
      display: block;
      color: ${colors.black};
      padding: 1rem;
      width: 100%;
      text-align: left;
      background-color: ${colors.white};
      border-left: 3px solid ${colors.primary};
    }
  }

  .stats-list {
    display: flex;
    gap: 0.5rem;
    font-size: 0.8rem;
    &>* {
      font-size: inherit;
      color: ${colors.grey6};
      &:not(:last-child) {
        position: relative;
        padding-right: 0.5rem;
        &::after {
          content: "";
          position: absolute;
          display: block;
          height: 2px; 
          width: 2px;
          right: -1px;
          top: 50%;
          transform: translateY(-50%);
          background-color: ${colors.grey6};
        }
      }
    }
  }

  ${'' /* MARGINS */}
  .mt-auto {
    margin-top: auto;
  }
  .ml-auto {
    margin-left: auto;
  }
  .mlr-auto {
    margin: 0 auto;
  }
  .mt-1 {
    margin-top: 1rem;
  }
  .mt-2 {
    margin-top: 2em;
  }
  .mb-1 {
    margin-bottom: 1rem;
  }
  .mb-2 {
    margin-bottom: 2rem;
  }
  .mg-auto {
    margin: auto;
  }

  ${'' /* TEXT ALIGN */}
  .al-left {
    text-align: left !important;
  }
  .al-right {
    text-align: right !important;
  }
  .al-center {
    text-align: center !important;
  }

  .grey-text {
    color: ${colors.grey6};
  }

  .link {
    display: inline-block;
    max-width: fit-content;
    position: relative;
    color: ${colors.primary};
    padding-bottom: 1px;
    font-size: inherit;
    &.underline {
      text-decoration: underline;
    }
    &:hover, &:focus {
      color: ${colors.primary};
      text-decoration: underline;
    }
    &.black {
      color: ${colors.grey3};
    }
    &.blue {
      color: ${colors.accent4};
    }
    &.error {
      color: ${colors.error};
    }
    &.grey {
      color: ${colors.grey6};
    }
    &.inherit {
      color: inherit;
    }
  }

  .invite-link {
    color: ${colors.primary};
    font-weight: 500;
  }

  .home-link-icon {
    display: block;
    img {
      width: 88px;
    }
    &.tall {
      img {
        width: 50px;
      }
    }
  }

  .btn-image {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 20px;
    }
  }
  
 
  ${''/* FORM CLASSES */}
  .field-column {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 1rem;
    min-width: 200px;
    &>* {
      width: initial;
    }
  }
  
  .field-row {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    align-items: center;
    &>* {
      flex: 1;
    }
  }
  
  .pseudo-field {
    padding: 16px 20px;
    background-color: ${colors.grey4};
    border: 1px solid ${colors.grey1};
    border-radius: 6px;
    color: ${colors.grey11};
    &.no-border {
      border: none;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .field-info {
    color: ${colors.grey6};
  }

  .button {
    user-select: none;
    width: fit-content;
    max-width: ${properties.maxFieldWidth};
    color: ${colors.white};
    background-color: ${functions.colorLuminance(colors.primary, -0.1)};
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 7px;
    padding: 12px 30px;
    font-weight: 700;
    font-size: 1rem;
    overflow: hidden;
    transition: none;
    position: relative;
    z-index: 1;
    &::before {
      transition: none;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 5px);
      z-index: -1;
      background-color: ${colors.primary};
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
    }
    &>* {
      color: inherit;
      font-weight: inherit;
      font-size: inherit;
    }
    img {
      width: 16px;
    }
    &.sm {
      padding: 7px 16px;
      font-size: 12px;
    }
    &.sm-radius {
      border-radius: 3px; 
    }
    &.mid-radius {
      border-radius: 11px;
    }
    &.lg-radius {
      border-radius: 20px;
    }
    &.submit {
      margin-top: 2rem;
    }
    &.white-black {
      color: ${colors.black};
      background-color: ${functions.colorLuminance(colors.white, -0.1)};
      &::before {
        background-color:  ${functions.colorLuminance(colors.white, -0.03)};
      }
    }
    &.white-primary {
      color: ${colors.primary};
      background-color: ${functions.colorLuminance(colors.white, -0.1)};
      &::before {
        background-color:  ${functions.colorLuminance(colors.white, -0.03)};
      }
    }
    &.black {
      color: ${colors.white};
      background-color: ${colors.black};
      border-color: ${colors.black};
    }
    &.transparent {
      color: ${colors.grey6};
      border: none;
      background-color: transparent;
      padding: 0;
      font-size: 12px;
      width: fit-content;
      &:disabled {
        background-color: transparent;
        opacity: 0.5;
      }
    }
    &.fill-width {
      width: 100%;
    }
    .fit-width,
    .auto-width {
      width: fit-content;
    }
    &.full-width {
      width: 100%;
      max-width: unset;
    }
    &.error {
      color: ${colors.white};
      background-color: ${functions.colorLuminance(colors.error, -0.1)};
      &::before {
        background-color:  ${functions.colorLuminance(colors.error, -0.03)};
      }
    }
    &.sync {
      width: 85px;
      justify-content: left;
    }
    ${''/* Pseudo classes (Keep at the bottom for specificity) */}
    &:hover, &:focus {
      cursor: ponter;
    }
    &:active, &:focus {
      transform: translateY(5px);
      &::before {
        height: 100%;
      }
    }
    &:disabled {
      color: ${colors.grey11};
      background-color: ${colors.white};
      border: 2px solid ${colors.grey9};
      cursor: default;
      &::before {
        display: none;
      }
      &:active {
        transform: none;
        &::before {
          display: none;
        }
      }
    }
  }

  .icon-button {
    height: fit-content;
    width: fit-content;
  }

  .validation-msg {
    font-size: 0.7rem;
    background-color: ${functions.rgba(colors.error, 0.1)};
    color: ${colors.error};
    padding: 7px 10px;
    border-radius: 5px;
  }

  ${'' /* Permissions, Statuses, Families, Ranks */}
  .role,
  .status,
  .rank,
  .family,
  .clan,
  .region,
  .desert {
    font-weight: 700;
    padding: 5px;
    border-radius: 5px;
    font-size: 8px !important;
    text-transform: uppercase;
    display: inline-flex;
    &.success,
    &.successful,
    &.admin,
    &.ancient,
    &.counsellor {
      background-color: ${functions.rgba(colors.primary, 0.1)};
      color: ${colors.primary};
    }
    &.generic-family,
    &.generic-clan,
    &.generic-desert,
    &.member,
    &.generic-region {
      background-color: ${functions.rgba(colors.secondary, 0.1)};
      color: ${colors.secondary};
    }
    &.medieval,
    &.processing,
    &.pending {
      background-color: ${functions.rgba(colors.accent6, 0.1)};
      color: ${colors.accent6};
    }
    &.colonial,
    &.failed,
    &.spear.carrier {
      background-color: ${functions.rgba(colors.accent7, 0.1)};
      color: ${colors.accent7};
    } 
    &.modern,
    &.guardian {
      background-color: ${functions.rgba(colors.accent4, 0.1)};
      color: ${colors.accent4};
    }
    &.prehistory,
    &.sailor {
      background-color: ${functions.rgba(colors.accent9, 0.1)};
      color: ${colors.accent9};
    }
    &.generic {
      background-color: ${functions.rgba(colors.black, 0.1)};
      color: ${colors.black};
    }
    &.super-admin {
      background-color: ${functions.rgba(colors.accent3, 0.1)};
      color: ${colors.accent3};
    }
    &.collaborator {
      background-color: ${functions.rgba(colors.accent4, 0.1)};
      color: ${colors.accent4};
    }
  }

  .avatar-placeholder {
    user-select: none;
    flex-shrink: 0;
    text-transform: uppercase;
    height: 48px;
    width: 48px;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 50%;
    background-color: ${colors.accent8};
    ${snippets.flexCenter}
    font-weight: 600;
  }



  ${''/* ::::: THIRD-PARTY COMPONENTS ::::: */}
  .Toastify {
    .Toastify__toast-container {
      max-width: 50rem;
      margin-left: calc(${properties.sidebarWidth} / 2);
      width: auto;
      padding: 0;
    }
    .Toastify__toast {
      padding: 0;
      width: auto;
      background: transparent;
      animation-duration: 300ms !important;
      box-shadow: none;
      .Toastify__toast-body {
        background: transparent;
        padding: 0;
        margin: 0;
        width: fit-content;
      }
    }
  }
`;

export default GlobalStyles;