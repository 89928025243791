import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { act, MouseEvent, useEffect, useState } from "react";
import moment from "moment";

import { useAppModal } from "../../../../components/layout/AppModal/utils";
import useSubscriptions from "../../../../requests/subscriptions";
import { consolePaths } from "../../../../routes/_console/paths";
import { currencySymbol } from "../../../../utils/strings";
import { decimalPrice } from "../../../../utils/numbers";
import { daysToMonths } from "../../../../utils/dates";
import { Loader } from "../../../../components/layout";
import {
  hasActiveSubscription
} from "../../../../requests/subscriptions/utils";
import {
  ActiveSubscription,
} from "../../../../requests/subscriptions/types";
import { ConsoleContext } from "../..";
import Styles from "./styles";
import useAuth from "../../../../requests/auth";



function SubscriptionDetail() {
  const { user } = useOutletContext<ConsoleContext>();
  const navigate = useNavigate();
  const { setAppModal, closeModal } = useAppModal();
  const { getTime } = useAuth();
  const {
    getMySubscription, cancelSubscription
  } = useSubscriptions();
  const [loading, setLoading] = useState<boolean>(true);
  const [periodEnded, setPeriodEnded] = useState<boolean>(false);
  const [activeTrialDays, setActiveTrialDays] = useState<number>();
  const [activeSubscription, setActiveSubscription] = useState<
    ActiveSubscription | null
  >(null);


  /************************/
  /** Cancel Subscription */
  const cancelCurrentSubscription = () => {
    setLoading(true);
    closeModal();
    cancelSubscription()
      .then((resData) => {
        setAppModal({
          title: "Subscription cancelled",
          children: resData.details,
          cancelCallback: () => {
            window.location.reload()
          }
        });
      }).catch(() => null)
      .finally(() => {
        setLoading(false);
      })
  }

  /**************************************/
  /** Confirm Subscription Cancellation */
  const cancelConfirm = (e: MouseEvent) => {
    e.preventDefault();
    setAppModal({
      title: "Are you sure?",
      children: (
        <div className="flex column">
          <div className="message">
            Do you want to cancel your subscription?
          </div>
          <div className="message">
            People in your family would no longer be able to access SONA.
          </div>
        </div>
      ),
      controls: (
        <div className="modal-controls">
          <button
            className="button full-width white-primary"
            onClick={() => {
              closeModal();
            }}
          >
            No, Keep my Subscription
          </button>
          <button
            className="button full-width"
            onClick={() => {
              cancelCurrentSubscription();
            }}
          >
            Yes, Cancel Subscription
          </button>
        </div>
      )
    });
  }

  const retrieveSubscription = () => {
    setLoading(true);
    getMySubscription()
      .then(async (subscription) => {
        const activeSubscription = hasActiveSubscription(subscription);
        if (activeSubscription) {
          setActiveSubscription(activeSubscription);
          // Set remaining trial days
          getTime()
            .then((today) => {
              if (activeSubscription.periodEnd) {
                setPeriodEnded(today > activeSubscription.periodEnd);
              }
              if (
                activeSubscription.freeTrialEnd &&
                (activeSubscription.freeTrialEnd) > today
              ) {
                const todayDate = new Date(today);
                const freeTrialEndDate = new Date(
                  activeSubscription.freeTrialEnd
                );
                // Clear the time part for accurate day comparison
                freeTrialEndDate.setHours(0, 0, 0, 0);
                todayDate.setHours(0, 0, 0, 0);
                const diffInMilliseconds = freeTrialEndDate.getTime() - todayDate.getTime();
                const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
                setActiveTrialDays(Math.abs(diffInDays));
              }
            })
            .catch(() => null)
            .finally(() => {
              setLoading(false)
            })
        } else {
          navigate(consolePaths.SUBSCRIPTION_OPTIONS);
          return;
        }
      }).catch(() => {
        navigate(consolePaths.SUBSCRIPTION_OPTIONS);
      });
  }


  useEffect(() => {
    retrieveSubscription();
  }, [])



  return (
    <Styles className="page-wrapper">
      {(!loading && activeSubscription) ? (
        <>
          <header>
            <div className="flex">
              <h1 className="title">
                Subscription
              </h1>
            </div>
          </header>

          {/* Active Plan */}
          <div className="subscription-plan">
            <section className="plan-summary">
              {/* Plan Name */}
              <div className="head flex align-center">
                <div className="name">
                  {activeSubscription.planPrice.plan.name} Plan
                </div>
                <span>-</span>
                <div className="price flex bold">
                  <div className="value">
                    {currencySymbol(activeSubscription.planPrice.currency)}
                    {decimalPrice(activeSubscription.planPrice.amount)}
                  </div>
                  <span>/</span>
                  <div className="interval">
                    {activeSubscription.planPrice.interval}
                  </div>
                </div>
              </div>
              {/* Plan Discount */}
              {(
                activeSubscription.discount &&
                !activeSubscription.discount.expired
              ) ? (
                <div className="discount flex">
                  <div className="title">
                    Active Discount
                  </div>
                  <span>-</span>
                  <div className="details grey-text">
                    {(activeSubscription.discount.
                      planPriceDiscount.percentage)}% off for {""
                    }{daysToMonths(activeSubscription.discount.
                      planPriceDiscount.daysDuration
                    )}
                  </div>
                </div>
              ) : null}
            </section>
            <div className="content">
              <div className="title">
                {activeSubscription.planPrice.plan.featureTitle}
              </div>
              <ul className="features flex column">
                {activeSubscription.planPrice.plan.planFeatures.map(
                  (feature) =>
                    <li className="feature" key={feature.id}>
                      {feature.description}
                    </li>
                )}
              </ul>
            </div>

            {/* Upgrade Plan - Family Admin */}
            {user.familyRole === "admin" ? (
              activeSubscription.recurringInfo ? (
                <div className="flex column">
                  <div className="subscription-expiry">
                    {(
                      ["cancelled", "expired"].includes(
                        activeSubscription.status
                      ) && !periodEnded
                    ) ? (
                      <>
                        <div>
                          Your subscription would expire on {moment(
                            activeSubscription.periodEnd
                          ).format("Do MMMM, YYYY")}.
                        </div>
                        <div>
                          Don't let your Family lose access!
                        </div>
                      </>
                    ) : (["cancelled", "expired"].includes(
                      activeSubscription.status
                    ) && periodEnded) ? (
                      `Your subscription expired on ${moment(
                        activeSubscription.periodEnd
                      ).format("Do MMMM, YYYY")}`
                    ) : (null)}
                  </div>
                  {activeTrialDays ? (
                    <div className="free-trial grey-text">
                      You have {activeTrialDays} days left on your free trial.
                    </div>
                  ) : null}
                  <Link
                    to={consolePaths.SUBSCRIPTION_OPTIONS}
                    className="link underline mt-1"
                  >
                    {activeSubscription.recurringInfo.recurring.status === "active" ? (
                      "Change Subscription"
                    ) : (
                      "Renew Subscription"
                    )}
                  </Link>
                  {activeSubscription.recurringInfo.recurring.status === "active" ? (
                    <button
                      onClick={cancelConfirm}
                      className="link underline cancel-plan"
                    >
                      Cancel Subscription
                    </button>
                  ) : null}
                </div>
              ) : (
                // Deprecated Controls
                <Link
                  to={consolePaths.SUBSCRIPTION_OPTIONS}
                  className="link underline change-plan"
                >
                  Change Subscription Plan
                </Link>
              )
            ) : null}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </Styles>
  );
}

export default SubscriptionDetail;