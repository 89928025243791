import { consolePaths } from "../../../routes/_console/paths";
import {
  ReactComponent as DashIcon
} from "../../../assets/vector-images/side-menu/00.svg";
import {
  ReactComponent as FamilyIcon
} from "../../../assets/vector-images/side-menu/01.svg";
import {
  ReactComponent as SubIcon
} from "../../../assets/vector-images/side-menu/02.svg";
import {
  ReactComponent as AccountIcon
} from "../../../assets/vector-images/side-menu/03.svg";
import {
  ReactComponent as ClanIcon
} from "../../../assets/vector-images/side-menu/04.svg";
import {
  ReactComponent as MissionsIcon
} from "../../../assets/vector-images/side-menu/05.svg";
import {
  ReactComponent as AffirmationsIcon
} from "../../../assets/vector-images/side-menu/06.svg";
import {
  ReactComponent as WalletIcon
} from "../../../assets/vector-images/side-menu/07.svg";
import {
  ReactComponent as StoreIcon
} from "../../../assets/vector-images/side-menu/08.svg";
import {
  ReactComponent as SupportIcon
} from "../../../assets/vector-images/side-menu/09.svg";
import {
  ReactComponent as VerificationIcon
} from "../../../assets/vector-images/side-menu/11.svg";
import { missionPaths } from "../../../routes/missions/paths";
import { User } from "../../../requests/users/types";
// import {
//   ReactComponent as DownloadIcon
// } from "../../../assets/vector-images/side-menu/05.svg";


type RouteLink = {
  name: string;
  path: string;
  icon: React.ReactElement;
}

export const getNavlinks = (
  user: User
): RouteLink[] => ([
  {
    name: "Get the App",
    path: consolePaths.DASHBOARD,
    icon: <DashIcon />
  },
  {
    name: "Family",
    path: consolePaths.FAMILY,
    icon: <FamilyIcon />
  },
  {
    name: "Subscription",
    path: consolePaths.SUBSCRIPTION,
    icon: <SubIcon />
  },
  {
    name: "Account",
    path: consolePaths.ACCOUNT,
    icon: <AccountIcon />
  },
  ...(
    user.primaryFamilyAdmin?.primaryAdminId &&
    user.primaryFamilyAdmin.primaryAdminId === user.id
  ) ? [{
    name: "Verification",
    path: consolePaths.VERIFICATION,
    icon: <VerificationIcon />
  }] : [],
  // {
  //   name: "Clan",
  //   path: consolePaths.CLAN,
  //   icon: <ClanIcon />
  // },
  {
    name: "Missions (Preview)",
    path: missionPaths.ONBOARDING_LESSON,
    icon: <MissionsIcon />
  },
  // {
  //   name: "Affirmations",
  //   path: consolePaths.AFFIRMATIONS,
  //   icon: <AffirmationsIcon />
  // },
  // {
  //   name: "Wallet",
  //   path: consolePaths.WALLET,
  //   icon: <WalletIcon />
  // },
  // {
  //   name: "Store",
  //   path: consolePaths.STORE,
  //   icon: <StoreIcon />
  // },
  // {
  //   name: "Support",
  //   path: consolePaths.SUPPORT,
  //   icon: <SupportIcon />
  // }
])