import styled from "styled-components";

import checkmark from "../../../../assets/vector-images/generic/check-green.svg";
import { colors, resolutions } from "../../../../styles";



const Styles = styled.div`
  .subscription-plan {
    ${'' /* Plan Name and Price */}
    padding: 2rem 3rem;
    border: 1px solid ${colors.grey1};
    border-radius: 20px;
    @media(max-width: ${resolutions.tabletLarge}) {
      padding: 0;
      border: none;
    }
    .discount {
      font-weight: 600;
    }
    .head {
      .name {
        font-weight: 600;
      }
      .price {
        gap: 4px;
        align-items: flex-end;
        font-weight: 600;
        .value {
          font-size: 1rem;
          color: ${colors.grey6};
        }
      }
    }
    ${'' /* Plan Description */}
    .content {
      margin: 2rem 0;
      .title {
        margin-bottom: 1rem;
        font-size: 1.1rem;
      }
      .features {
        gap: 12px;
        .feature {
          position: relative;
          padding-left: 20px;
          font-size: 1rem;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-image: url(${checkmark});
            height: 14px;
            width: 14px;
          }
        }
      }
    }

    .subscription-expiry {
      font-weight: 700;
      font-size: 1.1rem;
    }

    .cancel-plan {
      font-weight: 600;
      margin-top: 1rem;
      color: ${colors.error};
      &::after {
        background-color: ${colors.error};
      }
    }
  }
`;

export default Styles;