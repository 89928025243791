import { useMemo, useRef } from "react";

import { FormField } from "../../forms/Form/types";
import { QuizFormProps } from "./types";
import { Form } from "../../forms";
import Styles from "./styles";
import { Link } from "react-router-dom";



function Quiz({
  FormNavigation,
  values,
  setValues,
  nextStep,
  onSubmit,
  quiz,
  quizIndex,
  quizzesLength,
  lessonLink
}: QuizFormProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const quizNo = quizIndex + 1;
  const firstQuiz = quizIndex === 0;
  const finalQuiz = quizNo === quizzesLength;


  const updateParentValues = () => {
    if (finalQuiz) onSubmit();
    else nextStep();
  }

  const options: FormField[] = useMemo(
    () => quiz.options.map((option) => ({
      type: "radio",
      name: `${quiz.id}`,
      groupClass: "field-group",
      label: "",
      value: `${option.id}`,
      children: `${option.text}`
    })), [quiz.id])



  return (
    <Styles className="mission-quiz">
      <div className="question">
        {quizNo}. {quiz.question}
      </div>
      <Form
        fields={options}
        values={values}
        setValues={setValues}
        ref={formRef}
        onSubmit={updateParentValues}
      >
        <FormNavigation
          previous={firstQuiz ? (
            <Link
              to={lessonLink}
              className="button white-black previous"
            >
              Re-watch Lesson
            </Link>
          ) : undefined}
          next={finalQuiz ? (
            <button
              className="button next"
              type="submit"
            >
              Complete Mission
            </button>
          ) : undefined}
        />
      </Form>
    </Styles>
  );
}

export default Quiz;