import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { hasActiveSubscription } from "../../../requests/subscriptions/utils";
import { ActiveSubscription } from "../../../requests/subscriptions/types";
import { subscriptionPaths } from "../../../routes/subscriptions/paths";
import { profileSetupPaths } from "../../../routes/profileSetup/paths";
import success from "../../../assets/vector-images/panel/success.svg";
import { InfoPanel, Loader } from "../../../components/layout";
import useSubscriptions from "../../../requests/subscriptions";
import { consolePaths } from "../../../routes/_console/paths";
import { getPlanInterval } from "../../../utils/payments";
import { currencySymbol } from "../../../utils/strings";
import { decimalPrice } from "../../../utils/numbers";
import { User } from "../../../requests/users/types";
import useUsers from "../../../requests/users";
import Styles from "./styles";



function SubscriptionSuccess() {
  const navigate = useNavigate();
  const { getUserProfile } = useUsers();
  const { getMySubscription } = useSubscriptions();
  const [user, setUser] = useState<User>();
  const [subscription, setSubscription] = useState<ActiveSubscription>();


  useEffect(() => {
    getMySubscription()
      .then((subscription) => {
        const activeSubsription = hasActiveSubscription(subscription);
        if (activeSubsription)
          setSubscription(activeSubsription);
        else
          navigate(subscriptionPaths.FAILED);
      }).catch(() => {
        navigate(subscriptionPaths.FAILED);
      });
    getUserProfile()
      .then((resData) => {
        setUser(resData)
      }).catch(() => null);
  }, [])



  return (
    subscription ? (
      <Styles className="root-content">
        <div className="page-wrapper flex column center">
          <InfoPanel
            icon={success}
            title="Subscription Successful"
            description={(
              <div className="flex column">
                <div className="flex justify-between align-center plan">
                  <div>
                    {subscription.planPrice.plan.name} Plan - {" "}
                    {getPlanInterval(subscription.planPrice.interval)}
                  </div>
                  <div>
                    {currencySymbol(subscription.planPrice.currency)}
                    {decimalPrice(subscription.planPrice.amount)}
                  </div>
                </div>
                <div className="message">
                  You've successfully signed up for this plan<br />
                  and will be billed after your 7-day free trial ends
                </div>
              </div>
            )}
          >
            {user && user.completedOnboarding ? (
              <Link
                className="button"
                to={consolePaths.DASHBOARD}
              >
                Go to your Dashboard
              </Link>
            ) : (
              <Link
                to={profileSetupPaths.NAME_ENTRY}
                className="button"
              >
                Setup Profile
              </Link>
            )}
          </InfoPanel>
        </div>
      </Styles>
    ) : (
      <Loader message="Getting your Subscription ..." />
    )
  );
}

export default SubscriptionSuccess;
